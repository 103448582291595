import NameRectangle from "../../../assets/images/nameRectangle.png";
import { Typography } from "../../../ui/Typograhy";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { Button } from "../../../ui/buttons/Button";
import Veeba from "../../../assets/images/Vaping_device_Iqos_Veeba.png";
import VeevNow from "../../../assets/images/Vaping_device_Veev_Now.png";
import { useTranslation } from "react-i18next";
import { FiChevronLeft } from "react-icons/fi";
import { buttonLinksByLanguage } from "../../../shared/utils";
import i18n from "i18next";
import { routes } from "../../../navigation/routes";
import { ISection } from "../../../types";
import { TastePackCollapsible } from "../collapsibles/TastePackCollapsible";

export const VeebaSection = ({
  hasCollapsible = true,
  hasGoToStart = false,
  taste,
}: ISection) => {
  const { t } = useTranslation();
  const language = i18n.language;
  const isCz = language === "cz";
  const image = isCz ? VeevNow : Veeba;

  return (
    <div
      className={`flex ${
        isCz
          ? "bg-gradient-to-r bg-blue-gradient-from bg-blue-gradient-to"
          : "bg-dark-blue"
      } pt-10`}
    >
      <div className="relative flex flex-1">
        <img
          src={image}
          alt={"Veev"}
          className={`${
            !isCz ? "scale-75" : "-mr-4 mt-[11%]"
          } object-contain object-top`}
        />
      </div>
      <div className="z-50 mt-20 flex flex-1 flex-col items-start gap-5">
        <img src={NameRectangle} alt={"name-rectangle"} />
        <Typography.BodyMedium containerCss={[tw`ml-4 -mt-[50px] font-700`]}>
          {t("vapers").toUpperCase()}
        </Typography.BodyMedium>
        <Typography.H2>{isCz ? "VEEV NOW" : "VEEBA"}</Typography.H2>
        <Typography.Button containerCss={[tw`mb-4 max-w-[70%]`]}>
          {t("veebaDescription")}
        </Typography.Button>
        <div>
          <Link to={buttonLinksByLanguage[i18n.language].veeba}>
            <Button.Contained variant={"dark"} containerCss={[tw`mb-4 w-full`]}>
              {t("exploreMore")}
            </Button.Contained>
          </Link>
        </div>
        {hasCollapsible && (
          <TastePackCollapsible
            taste={taste}
            title={t("discoverVeebaFlavours")}
            product={"veeba"}
            variant={"light"}
          />
        )}
        {hasGoToStart && (
          <Typography.BodyMedium
            containerCss={[tw`flex items-center space-x-1 pb-12`]}
          >
            <FiChevronLeft />
            <Link
              to={routes.WELCOME}
              className={"underline underline-offset-4"}
            >
              {t("returnToProductAdvisor")}
            </Link>
          </Typography.BodyMedium>
        )}
      </div>
    </div>
  );
};
