import NameRectangle from "../../../assets/images/nameRectangle.png";
import { Typography } from "../../../ui/Typograhy";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { Button } from "../../../ui/buttons/Button";
import Veev from "../../../assets/images/Vaping_device_Iqos_Veev.png";
import VeevOne from "../../../assets/images/Vaping_Device_Veev_One.png";
import { useTranslation } from "react-i18next";
import { FiChevronLeft } from "react-icons/fi";
import { buttonLinksByLanguage } from "../../../shared/utils";
import i18n from "i18next";
import { routes } from "../../../navigation/routes";
import { ISection } from "../../../types";
import { TastePackCollapsible } from "../collapsibles/TastePackCollapsible";
/** @jsxImportSource @emotion/react */

export const VeevSection = ({
  hasCollapsible = true,
  hasGoToStart = false,
  taste,
}: ISection) => {
  const { t } = useTranslation();
  const language = i18n.language;
  const isCz = language === "cz";
  const image = isCz ? VeevOne : Veev;

  return (
    <div
      className={`flex overflow-hidden ${
        isCz ? "bg-darkest-blue" : "bg-blue"
      }  pt-10`}
    >
      <div
        className={`relative ${
          isCz ? "-mr-3 mt-[7%]" : "-mt-[7%]"
        }  flex flex-1 `}
      >
        <img src={image} alt={"Veev"} className="object-contain object-top" />
      </div>
      <div className="z-50 mt-20 flex flex-1 flex-col items-start gap-5">
        <img src={NameRectangle} alt={"name-rectangle"} />
        <Typography.BodyMedium containerCss={[tw`ml-4 -mt-[50px] font-700`]}>
          {t("vapers").toUpperCase()}
        </Typography.BodyMedium>
        <Typography.H2>{isCz ? "VEEV ONE" : "IQOS VEEV"}</Typography.H2>
        <Typography.Button
          containerCss={[
            tw`text-secondary mb-4 max-w-[60%]`,
            isCz && tw`text-white`,
          ]}
        >
          {t("veevDescription")}
        </Typography.Button>
        <div>
          <Link to={buttonLinksByLanguage[i18n.language].veev}>
            <Button.Contained variant={"dark"} containerCss={[tw`mb-4 w-full`]}>
              {t("exploreMore")}
            </Button.Contained>
          </Link>
        </div>
        {hasCollapsible && (
          <TastePackCollapsible
            taste={taste}
            variant={isCz ? "light" : "dark"}
            title={t("discoverVeevFlavours")}
            product={"veev"}
          />
        )}
        {hasGoToStart && (
          <Typography.BodyMedium
            containerCss={[
              tw`flex items-center text-secondary space-x-1 pb-12`,
              isCz && tw`text-white`,
            ]}
          >
            <FiChevronLeft />
            <Link
              to={routes.WELCOME}
              className={"underline underline-offset-4"}
            >
              {t("returnToProductAdvisor")}
            </Link>
          </Typography.BodyMedium>
        )}
      </div>
    </div>
  );
};
