import NameRectangle from "../../../assets/images/longer-name-rectangle.png";
import { Typography } from "../../../ui/Typograhy";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { Button } from "../../../ui/buttons/Button";
import IlumaPrime from "../../../assets/images/Heated_device_Iqos_Iluma_Prime.png";
import { TastePackCollapsible } from "../collapsibles/TastePackCollapsible";
import { useTranslation } from "react-i18next";
import { buttonLinksByLanguage } from "../../../shared/utils";
import i18n from "i18next";
import { ISection } from "../../../types";
import { FiChevronLeft } from "react-icons/fi";
import { routes } from "../../../navigation/routes";
import i18next from "i18next";

export const IlumaPrimeSection = ({
  hasCollapsible = true,
  hasGoToStart = false,
  taste,
}: ISection) => {
  const { t } = useTranslation();
  const isCz = i18next.language === "cz";

  return (
    <div className="flex bg-turqoise-green py-24 pt-10">
      <div className="relative flex flex-1">
        <img
          src={IlumaPrime}
          alt={"IlumaPrime"}
          className="object-contain object-top"
        />
      </div>
      <div className="flex flex-1 flex-col items-start gap-5">
        <img src={NameRectangle} alt={"name-rectangle"} />
        <Typography.BodyMedium
          containerCss={[tw`ml-4 -mt-[50px] uppercase font-700`]}
        >
          {t("heatedTobacco")}
        </Typography.BodyMedium>
        <Typography.H2>IQOS ILUMA PRIME</Typography.H2>
        <Typography.Button containerCss={[tw`text-secondary mb-4 max-w-[70%]`]}>
          {t("iqosIlumaPrimeDescription")}
        </Typography.Button>
        <div>
          <Link to={buttonLinksByLanguage[i18n.language].ilumaPrime}>
            <Button.Contained variant={"dark"} containerCss={[tw`mb-4 w-full`]}>
              {t("exploreMore")}
            </Button.Contained>
          </Link>
        </div>

        {hasCollapsible && (
          <TastePackCollapsible
            product={"terea"}
            title={t("discoverTereaFlavours")}
            taste={taste}
          />
        )}
        {hasGoToStart && (
          <Typography.BodyMedium
            containerCss={[
              tw`flex items-center text-secondary space-x-1 pb-12`,
            ]}
          >
            <FiChevronLeft />
            <Link
              to={routes.WELCOME}
              className={"underline underline-offset-4"}
            >
              {t("returnToProductAdvisor")}
            </Link>
          </Typography.BodyMedium>
        )}
      </div>
    </div>
  );
};
