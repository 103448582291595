import { Typography } from "../Typograhy";
import tw from "twin.macro";

export const ImageWText = ({
  image,
  text = "",
  id,
  imageWidthPercentage = 30,
  isSelected = false,
  onPress = () => {},
}) => {
  return (
    <div
      key={id}
      className={`flex w-[${imageWidthPercentage}%] min-w-[256px] max-w-[512px] flex-col items-center gap-y-4 text-center transition-all`}
      onClick={onPress}
    >
      <img
        src={image}
        alt={image.toString()}
        className={`flex max-h-[256px] rounded-2xl border-2 object-contain transition-all  ${
          isSelected ? "border-primary" : "border-transparent"
        }`}
      />
      <Typography.BodyMedium
        containerCss={isSelected ? [tw`text-primary`] : []}
      >
        {text}
      </Typography.BodyMedium>
    </div>
  );
};
