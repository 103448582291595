import NameRectangle from "../../../assets/images/longer-name-rectangle.png";
import { Typography } from "../../../ui/Typograhy";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { Button } from "../../../ui/buttons/Button";
import LilSolid from "../../../assets/images/Heated_device_Iqos_Lil_Solid.png";
import { useTranslation } from "react-i18next";
import { FiChevronLeft } from "react-icons/fi";
import { buttonLinksByLanguage } from "../../../shared/utils";
import i18n from "i18next";
import { routes } from "../../../navigation/routes";
import { ISection } from "../../../types";
import { TastePackCollapsible } from "../collapsibles/TastePackCollapsible";

export const LilSolidSection = ({
  hasCollapsible = true,
  hasGoToStart = false,
  taste,
}: ISection) => {
  const { t } = useTranslation();

  return (
    <div className="flex bg-blue-500 pt-10">
      <div className="relative mt-24 flex flex-1 items-start justify-center">
        <img
          src={LilSolid}
          alt={"LilSolid"}
          className="w-1/2 object-contain object-top"
        />
      </div>
      <div className="z-50 mt-20 flex flex-1 flex-col items-start gap-5">
        <img src={NameRectangle} alt={"name-rectangle"} />
        <Typography.BodyMedium
          containerCss={[tw`ml-4 -mt-[50px] uppercase font-700`]}
        >
          {t("heatedTobacco")}
        </Typography.BodyMedium>
        <Typography.H2>lil solid</Typography.H2>
        <Typography.Button containerCss={[tw`mb-4 max-w-[60%]`]}>
          {t("lilSolidDescription")}
        </Typography.Button>
        <div>
          <Link to={buttonLinksByLanguage[i18n.language].lilSolid}>
            <Button.Contained variant={"dark"} containerCss={[tw`mb-4 w-full`]}>
              {t("exploreMore")}
            </Button.Contained>
          </Link>
        </div>

        {hasCollapsible && (
          <TastePackCollapsible
            taste={taste}
            title={t("discoverFiitFlavours")}
            product={"fiit"}
            variant={"light"}
          />
        )}
        {hasGoToStart && (
          <Typography.BodyMedium
            containerCss={[tw`flex items-center space-x-1 pb-12`]}
          >
            <FiChevronLeft />
            <Link
              to={routes.WELCOME}
              className={"underline underline-offset-4"}
            >
              {t("returnToProductAdvisor")}
            </Link>
          </Typography.BodyMedium>
        )}
      </div>
    </div>
  );
};
